"use client"

import { useCallback } from "react"

interface Props {
  src: string
  srcset?: string
  alt?: string
  width?: number | string
  height?: number | string
  errSrc?: string
  className?: string
}

export function LazyImage({
  src,
  srcset,
  alt = "",
  width,
  height,
  errSrc,
  className
}: Props) {
  const handleError = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (errSrc) {
        e.currentTarget.src = errSrc
      }
    },
    [errSrc]
  )

  return (
    <img
      src={src}
      srcSet={srcset}
      alt={alt}
      width={width}
      height={height}
      loading="lazy"
      onError={handleError}
      className={className}
    />
  )
}

import type { EmblaCarouselType } from "embla-carousel"
import { useCallback, useEffect, useState } from "react"

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean
  nextBtnDisabled: boolean
  onPrevButtonClick: () => void
  onNextButtonClick: () => void
}

type UseDotButtonType = {
  selectedIndex: number
  scrollSnaps: number[]
  onDotButtonClick: (index: number) => void
}

type UseSelectedSnapDisplayType = {
  selectedSnap: number
  snapCount: number
}

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on("reInit", onSelect)
    emblaApi.on("select", onSelect)
  }, [emblaApi, onSelect])

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  }
}

export const useDotButton = (
  emblaApi: EmblaCarouselType | undefined
): UseDotButtonType => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
    },
    [emblaApi]
  )

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on("reInit", onInit)
    emblaApi.on("reInit", onSelect)
    emblaApi.on("select", onSelect)
  }, [emblaApi, onInit, onSelect])

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick
  }
}

// 表示中のスライドのインデックスと, スライドの総数を返す
export const useSelectedSnapDisplay = (
  emblaApi: EmblaCarouselType | undefined
): UseSelectedSnapDisplayType => {
  const [selectedSnap, setSelectedSnap] = useState(0)
  const [snapCount, setSnapCount] = useState(0)

  const updateScrollSnapState = useCallback((emblaApi: EmblaCarouselType) => {
    setSnapCount(emblaApi.scrollSnapList().length)
    setSelectedSnap(emblaApi.selectedScrollSnap())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    updateScrollSnapState(emblaApi)
    emblaApi.on("select", updateScrollSnapState)
    emblaApi.on("reInit", updateScrollSnapState)
  }, [emblaApi, updateScrollSnapState])

  return {
    selectedSnap,
    snapCount
  }
}

// スライドのオリジナルのインデックスにスクロールする
// 現状のscrollToはslidesToScrollと組み合わせた時におかしな挙動おをするため、カスタムフックを追加
// この機能はv9で追加される予定なので、リリースされたら削除する
// https://github.com/davidjerleke/embla-carousel/discussions/1043
// 実装自体は下記を参考にしている
// https://github.com/davidjerleke/embla-carousel/pull/1086/files#diff-cb6e53e6d149a1a6784922356eaff9e7a5f0a43a0d30203fec752c39b60aeaf0R180
export const useScrollToSlide = (emblaApi: EmblaCarouselType | undefined) => {
  const scrollToSlide = useCallback(
    (subject: number | HTMLElement, jump?: boolean) => {
      if (!emblaApi) return

      const index =
        typeof subject === "number"
          ? subject
          : emblaApi.slideNodes().indexOf(subject)

      if (index === -1) return

      // `slideRegistry` から対象スライドが属するスナップポイントを取得
      const slideRegistry = emblaApi.internalEngine().slideRegistry
      const snapIndex = slideRegistry.findIndex((group: number[]) =>
        group.includes(index)
      )

      if (typeof snapIndex === "number") {
        emblaApi.scrollTo(snapIndex, jump)
      }
    },
    [emblaApi]
  )

  return scrollToSlide
}

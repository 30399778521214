import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_assets%2Fstyles%2Fvariables.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3WV32%2BbMBDH3%2FdXnISmbBKp%2FAMM3N72sE2aNk3r0x6NsVOaBNJA2nTT%2FvfJpF19hsYhUj7%2BHr7j7jg89v0If94ArNdju%2BvGA0NIGiONVB8CyhES57TShIqJ2trWIZUTdSHKPMr8CmmOkFjhV0gVQmJyv0JaICSV8yukJUJSKL9CWiEkYvqEVCMkmeQhqhESxliIDMJxU%2Bt3LIWn75XM34eKZq6gAjsXKCJwcwGTRLFBSPKcPICbJyOR5ym8%2FLCrjFi2l0RppkN6OwXalKYI6RYh0XWhjAzpzp9dF86QXO09VXVtydPqfAadUhWhPUIidSPpaYfFwrpDSHhZFI7k6jhPzOCPUowJ4uw4F54mpLPGhvR%2BonVFtQ%2FeU5NpRegZYa0Ph51dD4%2FDaPcpfNy13fabNtfT%2F099N6aw%2BtnX%2FdivUlh9aY9603Y9XOtuIOCr3p7gcz%2FetAZ%2BHPvvfvPXM1mlMOhuWA%2F22JKyfrwUcIh%2BP6VfZik8X3FdcbYoiuqX83l%2FcvFadRXUVr6mi87Ilt8i%2BbO5YCkolkJ%2BCSOyVsuyOJACQWSHM2ElAi8jViFwFTGNwGPbGoGLiBkEziLWILArMYDVg133pxHYQPat35dP%2B20Xbzu%2FTZ%2FLZrIg%2Fc5vEHL2lqAWIfb5FiEOd7sQ7s6Hwe73muL9hEnx8w4h6hLeI5QROiAUjLxM%2BZ1HVHVEUBEafFgUjQhZhE4IMqfoHkFGqgcEEaHzHPluYlmdN9Td3whJzarIP%2BHnX1HL0pFsCN80thHOkPeq8BOwqZTNSoL9CLTGcUfV0xjUTlriifBz0ElrHB2ualL72xBcXLC1huBynnVRzYpDaAQG4nCeqhrYpc%2Byl0HES9Jjov6vV4t6wajeeP10b7F8gBDUoJkM%2FM19Ly8ZeI%2F%2B%2FgNe%2Fdw9qQgAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_components%2FUserIconName%2Findex.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2B2Uy5KiMBRA93zF3UxVu4hF1PaR3syfTAVywbQhYUIUdMp%2FnyLaCG3o1prtrMBw7utcZPqLit%2Fx6WRm8CcCELIqFT8yyK0UbxH4K3FYlIo7JKlR%2B0JXDPjeGaCZbZHLIcl5yWBdNu0RVzLXRDosKgYpaoeedJbrSjppNIMDty%2BEOKm0K6mYvEXnaNr10t3Fvqt%2BhUVbocfOPfFlZgCHjSMCU2P5hdFG4yDNYjh%2BptAPcis5MlQvxXLE4DMpPg3u7beuSaZMza4iRkLoJaQtF48grx6ppXBbBjSOf7RNve8rJ7MjSY12qB2DquQpkgRdjTgslnysw9ih56PXrKRGskWZbx0DOl0MY7u7jc%2BSGe1IfYUTo8QIvRqrSelkJGQ9FnIaRrw%2FO05vvaTGZCcdSYzf7MdPH5MqXpQMaP9BYhpirPSCD2idTLlqn5sD2styt1KI1jdAbawgiUW%2BY%2BAvhCs17Pz5WR8W%2BrNAITlUqUXUwLWAl0Jqcn1rNsu4bCY%2Bxe0%2FyLbtGP4QwJQ8le74KXfmlZ77Yfwa4N%2BESp6QAb1%2BQAZcGuBogMN7brYIcPk9N58FOBmoG%2BJ2D%2FZXBLiuv%2B%2B8r5br1vvlmDfdOl43d%2BsIeV0%2B6DV%2B0GuIC3idhfYZ8hryFfIaqhvyOv%2Fa603garn6L%2FBfBHZvYHT%2BC9u1WspNCAAA%22%7D"
export var base00 = '_1dq0zzo7';
export var base06Secondary = '_1dq0zzo8';
export var bold = '_1dq0zzo9';
export var largeName = '_1dq0zzoe';
export var link = '_1dq0zzo3';
export var medium = '_1dq0zzo1';
export var name = '_1dq0zzob _1dq0zzoa';
export var nameContainer = '_1dq0zzo4';
export var nameMetaWrapper = '_1dq0zzo6';
export var small = '_1dq0zzo0';
export var smallName = '_1dq0zzoc';
export var smallUserMeta = '_1dq0zzok';
export var spaceBetween = '_1dq0zzo5';
export var userIconName = '_1dq0zzo2';
export var userMeta = '_1dq0zzoj _1dq0zzoi';
export var xlargeName = '_1dq0zzog';
export var xlargeUserMeta = '_1dq0zzom';
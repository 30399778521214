import { IconBase } from "@/app/_components/icons/IconBase"
import Icon from "@/app/_components/icons/assets/64x64/ImgBlankUser.svg"

interface Props extends React.ComponentPropsWithoutRef<"span"> {
  width?: number
  height?: number
}

export function BlankUserIcon({ width = 64, height = 64, ...props }: Props) {
  return (
    <IconBase width={width} height={height} currentColor={false} {...props}>
      <Icon />
    </IconBase>
  )
}

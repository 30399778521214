import "../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_assets%2Fstyles%2Fvariables.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3WV32%2BbMBDH3%2FdXnISmbBKp%2FAMM3N72sE2aNk3r0x6NsVOaBNJA2nTT%2FvfJpF19hsYhUj7%2BHr7j7jg89v0If94ArNdju%2BvGA0NIGiONVB8CyhES57TShIqJ2trWIZUTdSHKPMr8CmmOkFjhV0gVQmJyv0JaICSV8yukJUJSKL9CWiEkYvqEVCMkmeQhqhESxliIDMJxU%2Bt3LIWn75XM34eKZq6gAjsXKCJwcwGTRLFBSPKcPICbJyOR5ym8%2FLCrjFi2l0RppkN6OwXalKYI6RYh0XWhjAzpzp9dF86QXO09VXVtydPqfAadUhWhPUIidSPpaYfFwrpDSHhZFI7k6jhPzOCPUowJ4uw4F54mpLPGhvR%2BonVFtQ%2FeU5NpRegZYa0Ph51dD4%2FDaPcpfNy13fabNtfT%2F099N6aw%2BtnX%2FdivUlh9aY9603Y9XOtuIOCr3p7gcz%2FetAZ%2BHPvvfvPXM1mlMOhuWA%2F22JKyfrwUcIh%2BP6VfZik8X3FdcbYoiuqX83l%2FcvFadRXUVr6mi87Ilt8i%2BbO5YCkolkJ%2BCSOyVsuyOJACQWSHM2ElAi8jViFwFTGNwGPbGoGLiBkEziLWILArMYDVg133pxHYQPat35dP%2B20Xbzu%2FTZ%2FLZrIg%2Fc5vEHL2lqAWIfb5FiEOd7sQ7s6Hwe73muL9hEnx8w4h6hLeI5QROiAUjLxM%2BZ1HVHVEUBEafFgUjQhZhE4IMqfoHkFGqgcEEaHzHPluYlmdN9Td3whJzarIP%2BHnX1HL0pFsCN80thHOkPeq8BOwqZTNSoL9CLTGcUfV0xjUTlriifBz0ElrHB2ualL72xBcXLC1huBynnVRzYpDaAQG4nCeqhrYpc%2Byl0HES9Jjov6vV4t6wajeeP10b7F8gBDUoJkM%2FM19Ly8ZeI%2F%2B%2FgNe%2Fdw9qQgAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_components%2FCard%2FPhotoCard%2FPhotoCardBasic.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8VTy47iMBC88xXWSCsNB6OERwDnsn%2ByauJOYmHsyHYgzIp%2FX9kkEBLEMKc9xe7uVFVXu2d%2F5qv9V7GPyd8JIc6AssIJrRg5gvmk1AmpXBXzaerT2DjKMdMGrjVKK0wnl8msRZkHlEp3GAYlOHFE%2F%2FNJcFcyEkfRL3%2FNJTaMRCS6RXbacDTUABe1HfDvA78%2BosmlPjFSCs5Rpd9J7inrDlGQOOCK%2BqWLUMGFrSScGdlJne3Td9uqgHOhCup01QV7yAxyhybgZ1o5VI6Rj49HcNhZLWv3DLxEUZTufhfKogvqCdlBti%2BMrhWnmZbaPLqRT68kQjk0FI%2BonB2PbzkYX19KaCgwjZ15KfMOnzwaWxjBfbn%2FUoeHSoJDavTJMgK10%2BNcpmV9UJaRODchCxUjy6rx5wOYQqir722onYV%2FZW3kbtKjPYtgD0hRKHobzA4sSqHwB69sEzoc2OEx6M2T2TIN0x%2BN6ByQfh%2BQCyA2M4iKgOLk8yAUbSG3SVQ100DSUcas9GtBHlfQv%2FCG2hK435Y%2BzRxCr%2B%2Bs26VHk3SHVYvf93tzdbcdSHu7vBCZtCC6gky484A9H7Gv2%2FpcK0et%2BEJG4sWYZvukbNmVfeftOtl4b69haG6Wr7ZDy5%2Bpmb%2BnZvFazZ12naz%2FB%2B2t28nlH6tQhEkaBgAA%22%7D"
export var cardComment = '_25kzgk8 _25kzgk7';
export var cardCommentLarge = '_25kzgk9';
export var cardContainer = '_25kzgk1';
export var cardPhoto = '_25kzgk2';
export var cardPhotoImage = '_25kzgk4';
export var cardPhotoWrapper = '_25kzgk3';
export var cardUser = '_25kzgk6';
export var large = '_25kzgk5';
export var thumbnailView = '_25kzgk0';
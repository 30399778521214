import type { Size } from "@/app/_components/UserIcon"
import clsx from "clsx"
import { useMemo } from "react"
import React from "react"
import * as styles from "./index.css"

interface Props {
  size: Size
  color: "base06" | "base06Secondary" | "base00"
  layout?: string
  style?: string
  area?: string
  gender?: string
  birth?: string
  country?: string
  region?: string
  job?: string
}

export function UserMetaList({
  size,
  color,
  layout,
  style,
  area,
  gender,
  birth,
  country,
  region,
  job
}: Props) {
  const metaList = useMemo(
    () =>
      [
        layout,
        style,
        area,
        gender,
        birth,
        country && region ? `${country},${region}` : null,
        job
      ].filter(
        meta => meta !== null && meta !== undefined && meta !== ""
      ) as string[],
    [layout, style, area, gender, birth, country, region, job]
  )

  if (metaList.length === 0) {
    return null
  }

  return (
    <div
      className={clsx(
        styles.userMeta,
        (size === "xsmall" || size === "small") && styles.smallUserMeta,
        size === "xlarge" && styles.xlargeUserMeta,
        color === "base06Secondary" && styles.base06Secondary,
        color === "base00" && styles.base00
      )}
    >
      {metaList.map((meta, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: 一意のkeyがない
        <span key={index}>
          {meta}
          {index < metaList.length - 1 && <span> | </span>}
        </span>
      ))}
    </div>
  )
}

"use client"

import { BlankUserIcon } from "@/app/_components/icons/BlankUserIcon"
import { assignInlineVars } from "@vanilla-extract/dynamic"
import { clsx } from "clsx"
import { useCallback, useMemo, useState } from "react"
import * as styles from "./index.css"

export type Size = "xsmall" | "small" | "medium" | "large" | "xlarge"

interface Props {
  name: string
  imageUrl: string
  link?: string
  size?: Size
  withBorder?: boolean
  className?: string
}

export function UserIcon({
  name,
  imageUrl,
  link,
  size = "medium",
  withBorder = false,
  className
}: Props) {
  const [imageError, setImageError] = useState(false)

  const handleImageError = useCallback((): void => {
    setImageError(true)
  }, [])

  // Nuxt側はminimum: 24px, small: 32px, medium: 40px, large: 56pxとなっているので移行時に注意
  const sizeMap = {
    xsmall: 16,
    small: 24,
    medium: 40,
    large: 64,
    xlarge: 160
  } as const satisfies Record<Size, number>

  const imageSize = sizeMap[size]

  const iconImage = useMemo(
    () =>
      imageError ? (
        <BlankUserIcon width={imageSize} height={imageSize} />
      ) : (
        <img
          src={imageUrl}
          alt={name}
          width={imageSize}
          height={imageSize}
          onError={handleImageError}
          className={styles.image}
        />
      ),
    [imageError, imageUrl, name, imageSize, handleImageError]
  )

  const sizeStyles = useMemo(() => {
    return assignInlineVars({
      [styles.widthVar]: `${imageSize}px`,
      [styles.heightVar]: `${imageSize}px`
    })
  }, [imageSize])

  return (
    <div className={clsx(styles.iconContainer, className)}>
      {link ? (
        <a
          href={link}
          className={clsx(
            styles.link,
            styles.imageWrapper,
            withBorder && styles.withBorder,
            size === "xlarge" && styles.xlarge
          )}
          style={sizeStyles}
        >
          {iconImage}
        </a>
      ) : (
        <div
          className={clsx(
            styles.imageWrapper,
            withBorder && styles.withBorder,
            size === "xlarge" && styles.xlarge
          )}
          style={sizeStyles}
        >
          {iconImage}
        </div>
      )}
    </div>
  )
}

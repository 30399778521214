import Icon from "@/app/_components/icons/assets/8x16/icon_arrow_accordion_open.svg"
import { IconBase } from "./IconBase/IconBase"

interface Props extends React.ComponentPropsWithoutRef<"span"> {
  width?: number
  height?: number
}

export function ArrowOpenIcon({ width = 16, height = 8, ...props }: Props) {
  return (
    <IconBase width={width} height={height} {...props}>
      <Icon />
    </IconBase>
  )
}

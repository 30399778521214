import { type Size, UserIcon } from "@/app/_components/UserIcon"
import clsx from "clsx"
import { useMemo } from "react"
import { LineClamp } from "../LineClamp"
import { RcsBadgeIcon } from "../icons/RcsBadgeIcon"
import { UserMetaList } from "./UserMetaList"
import * as styles from "./index.css"

interface Props {
  name: string
  imageUrl: string
  link?: string
  size?: Size
  layout?: string
  style?: string
  area?: string
  gender?: string
  birth?: string
  country?: string
  region?: string
  job?: string
  shopPageId?: number | null
  color?: "base06" | "base06Secondary" | "base00"
  isBold?: boolean
  isSpaceBetween?: boolean
}

export function UserIconName({
  name,
  imageUrl,
  link,
  size = "medium",
  layout,
  style,
  area,
  gender,
  birth,
  country,
  region,
  job,
  shopPageId,
  color = "base06",
  isBold = false,
  isSpaceBetween = false
}: Props) {
  const iconNameContent = useMemo(() => {
    return (
      <>
        <UserIcon name={name} imageUrl={imageUrl} size={size} />

        <div className={styles.nameContainer}>
          <div
            className={clsx(
              styles.nameMetaWrapper,
              size === "medium" && styles.medium,
              (size === "xsmall" || size === "small") && styles.small,
              isSpaceBetween && styles.spaceBetween
            )}
          >
            <LineClamp
              text={name}
              line={1}
              className={clsx(
                styles.name,
                (size === "xsmall" || size === "small") && styles.smallName,
                size === "large" && styles.largeName,
                size === "xlarge" && styles.xlargeName,
                color === "base00" && styles.base00,
                color === "base06Secondary" && styles.base06Secondary,
                isBold && styles.bold
              )}
            />

            {!shopPageId && (
              <UserMetaList
                size={size}
                color={color === "base00" ? "base00" : "base06Secondary"}
                layout={layout}
                style={style}
                area={area}
                gender={gender}
                birth={birth}
                country={country}
                region={region}
                job={job}
              />
            )}
          </div>
          {shopPageId && <RcsBadgeIcon />}
        </div>
      </>
    )
  }, [
    name,
    imageUrl,
    size,
    shopPageId,
    layout,
    style,
    area,
    gender,
    birth,
    country,
    region,
    job,
    color,
    isBold,
    isSpaceBetween
  ])

  if (link !== undefined) {
    return (
      <a
        href={link}
        className={clsx(
          styles.userIconName,
          (size === "xsmall" || size === "small") && styles.small,
          styles.link
        )}
      >
        {iconNameContent}
      </a>
    )
  }

  return (
    <div
      className={clsx(
        styles.userIconName,
        (size === "xsmall" || size === "small") && styles.small
      )}
    >
      {iconNameContent}
    </div>
  )
}

"use client"

import { LazyImage } from "@/app/_components/LazyImage"
import clsx from "clsx"
import { useMemo } from "react"
import * as styles from "./PhotoCardBasic.css"

// TODO: 型の共通化を考える(一旦ここに定義する)
export interface PhotoCardBasicPhoto {
  id: number
  hashId: string
  imageUrl: string
  largeImageUrl: string
  commentText?: string
  photoImages?: {
    photoImageId: number
    imageUrl: string
    largeImageUrl: string
  }[]

  // TODO: 以下のような感じ？
  // tags?: {
  //   id: number
  //   name: string
  //   displayName: string
  // }[]
}

export interface PhotoCardBasicUser {
  id: number
  name: string
  iconUrl: string
  layout?: string
  style?: string
}

interface Props {
  size?: "medium" | "large"
  imageUrl: string
  largeImageUrl: string
  user?: PhotoCardBasicUser
  thumbnailView?: boolean
  // items: any[] TODO
  className?: string
}

export function PhotoCardBasicThumbnail({
  size = "medium",
  imageUrl,
  largeImageUrl,
  user,
  thumbnailView = false,
  className
}: Props) {
  const srcSet = useMemo(
    () =>
      size === "large" ? `${imageUrl} 1x, ${largeImageUrl} 2x` : `${imageUrl}`,
    [imageUrl, largeImageUrl, size]
  )

  return (
    // TODO: ItemPosition
    <div
      className={clsx(
        styles.cardPhoto,
        thumbnailView && styles.thumbnailView,
        className
      )}
    >
      <div className={styles.cardPhotoWrapper}>
        <LazyImage
          className={styles.cardPhotoImage}
          src={imageUrl}
          srcset={srcSet}
          alt={user !== undefined ? `${user.name}さんの実例写真` : "実例写真"}
        />
      </div>
    </div>
  )
}

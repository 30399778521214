"use client"

import { showDesktop, showMobile } from "@/app/_assets/styles/media.css"
import { PhotoCardBasicThumbnail } from "@/app/_components/Card/PhotoCard/PhotoCardBasicThumbnail"
import { LineClamp } from "@/app/_components/LineClamp"
import { UserIconName } from "@/app/_components/UserIconName"
import { useDotButton, useSelectedSnapDisplay } from "@/app/_hooks/carousel"
import { changeSlideReformPhotoCard } from "@/app/_utils/events"
import type {
  PickupPhotoUser,
  ReformPocCollectionPhoto
} from "@/app/tag/[id]/_components/types"
import clsx from "clsx"
import useEmblaCarousel from "embla-carousel-react"
import { useEffect } from "react"
import * as styles from "./PhotoCardBasicCarousel.css"

interface Props {
  photo: ReformPocCollectionPhoto
  user?: PickupPhotoUser
  thumbnailView?: boolean
  index: number
  size?: "medium" | "large"
  className?: string
  gtmClass?: string
}

export function PhotoCardBasicCarousel({
  photo,
  user,
  thumbnailView = false,
  index,
  size,
  className,
  gtmClass
}: Props) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15
  })
  const { selectedSnap, snapCount } = useSelectedSnapDisplay(emblaApi)
  const { selectedIndex, onDotButtonClick } = useDotButton(emblaApi)

  // スライドが変わったらイベントを送信
  useEffect(() => {
    if (emblaApi) {
      changeSlideReformPhotoCard(photo.id, selectedSnap + 1, index)
    }
  }, [emblaApi, selectedSnap, index, photo.id])

  return (
    <div className={styles.card}>
      <a
        href={`/photo/${photo.hashId}`}
        className={clsx(styles.cardContainer, className, gtmClass)}
      >
        <div
          className={clsx(
            styles.imagesContainer,
            thumbnailView && styles.thumbnailView
          )}
        >
          <div className={styles.embra}>
            <div className={styles.viewport} ref={emblaRef}>
              <div className={styles.container}>
                {photo.photoImages.map(photoImage => (
                  <PhotoCardBasicThumbnail
                    imageUrl={photoImage.imageUrl}
                    largeImageUrl={photoImage.largeImageUrl}
                    user={user}
                    thumbnailView={thumbnailView}
                    size={size}
                    key={photoImage.photoImageId}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        {!thumbnailView && (
          <div
            className={clsx(styles.cardUser, size === "large" && styles.large)}
          >
            {photo.commentText && (
              <div
                className={clsx(
                  styles.cardComment,
                  size === "large" && styles.cardCommentLarge
                )}
              >
                <div className={showDesktop}>
                  <LineClamp
                    text={photo.commentText}
                    line={size === "large" ? 4 : 2}
                  />
                </div>
                <div className={showMobile}>
                  <LineClamp text={photo.commentText} line={2} />
                </div>
              </div>
            )}

            {user !== undefined && (
              <UserIconName
                name={user.name}
                imageUrl={user.iconUrl}
                size={size === "large" ? "small" : "xsmall"}
                color={photo.commentText ? "base06Secondary" : "base06"}
                layout={user.layout}
                style={user.style}
                isSpaceBetween
              />
            )}
          </div>
        )}
      </a>

      <div className={styles.dummyThumbnail}>
        {/* スライドのドット部分 */}
        {photo.photoImages.length > 1 && (
          <div className={styles.dots}>
            {photo.photoImages.map((photoImage, index) => (
              <div
                key={photoImage.photoImageId}
                onClick={() => onDotButtonClick(index)}
                className={clsx(
                  styles.dot,
                  selectedIndex === index && styles.selected
                )}
              />
            ))}
          </div>
        )}

        {/* 右上のAfter/Beforeのラベル */}
        {snapCount > 1 && (
          <div className={styles.selectedSnapLabel}>
            <span
              className={clsx(
                styles.reformLabel,
                selectedSnap === 0 && styles.active
              )}
              // ここもスライドさせるクリック要素とする
              onClick={() => onDotButtonClick(0)}
            >
              After
            </span>
            <span className={clsx(styles.reformLabel)}> | </span>
            <span
              className={clsx(
                styles.reformLabel,
                selectedSnap === 1 && styles.active
              )}
              // ここもスライドさせるクリック要素とする
              onClick={() => onDotButtonClick(1)}
            >
              Before
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

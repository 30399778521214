import Icon from "@/app/_components/icons/assets/8x16/icon_arrow_right.svg"
import { IconBase } from "./IconBase/IconBase"

interface Props extends React.ComponentPropsWithoutRef<"span"> {
  width?: number
  height?: number
}

export function ArrowRightIcon({ width = 8, height = 16, ...props }: Props) {
  return (
    <IconBase width={width} height={height} {...props}>
      <Icon />
    </IconBase>
  )
}

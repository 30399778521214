import MediumIcon from "@/app/_components/icons/assets/16x16/icon_shop_offical_medium.svg"
import LargeIcon from "@/app/_components/icons/assets/20x20/icon_shop_offical_large.svg"
import { IconBase } from "./IconBase/IconBase"

interface Props extends React.ComponentPropsWithoutRef<"span"> {
  size?: "medium" | "large"
}

export function RcsBadgeIcon({ size = "medium", ...props }: Props) {
  const iconStyle =
    size === "large" ? { width: 24, height: 24 } : { width: 16, height: 16 }

  return (
    <IconBase
      width={iconStyle.width}
      height={iconStyle.height}
      currentColor={false}
      {...props}
    >
      {size === "large" ? <LargeIcon /> : <MediumIcon />}
    </IconBase>
  )
}

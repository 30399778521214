"use client"

import { ArrowLeftIcon } from "@/app/_components/icons/ArrowLeftIcon"
import { ArrowRightIcon } from "@/app/_components/icons/ArrowRightIcon"
import clsx from "clsx"
import { type StyleHTMLAttributes, useMemo } from "react"
import * as styles from "./CarouselButton.css"

export type Size = "small" | "medium" | "large"
export type Color = "black" | "white"
interface Props {
  direction: "prev" | "next"
  size?: Size
  color?: Color
  onClick(): void
  className?: string
  style?: StyleHTMLAttributes<HTMLDivElement>
}

export function CarouselButton({
  direction,
  size = "medium",
  color = "black",
  onClick,
  className,
  style
}: Props) {
  const iconWidth = useMemo(() => {
    return size === "large" ? 16 : 8
  }, [size])
  const iconHeight = useMemo(() => {
    return size === "large" ? 32 : 16
  }, [size])

  return (
    <div
      className={clsx(
        styles.carouselButton,
        size === "small"
          ? styles.small
          : size === "medium"
            ? styles.medium
            : size === "large"
              ? styles.large
              : undefined,
        direction === "prev"
          ? styles.prev
          : direction === "next"
            ? styles.next
            : undefined,
        color === "white" && styles.white,
        className
      )}
      style={style}
      onClick={onClick}
    >
      {direction === "prev" ? (
        <ArrowLeftIcon
          className={clsx(styles.icon, color === "white" && styles.white)}
          width={iconWidth}
          height={iconHeight}
        />
      ) : direction === "next" ? (
        <ArrowRightIcon
          className={clsx(styles.icon, color === "white" && styles.white)}
          width={iconWidth}
          height={iconHeight}
        />
      ) : null}
    </div>
  )
}

import "../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_assets%2Fstyles%2Fvariables.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3WV32%2BbMBDH3%2FdXnISmbBKp%2FAMM3N72sE2aNk3r0x6NsVOaBNJA2nTT%2FvfJpF19hsYhUj7%2BHr7j7jg89v0If94ArNdju%2BvGA0NIGiONVB8CyhES57TShIqJ2trWIZUTdSHKPMr8CmmOkFjhV0gVQmJyv0JaICSV8yukJUJSKL9CWiEkYvqEVCMkmeQhqhESxliIDMJxU%2Bt3LIWn75XM34eKZq6gAjsXKCJwcwGTRLFBSPKcPICbJyOR5ym8%2FLCrjFi2l0RppkN6OwXalKYI6RYh0XWhjAzpzp9dF86QXO09VXVtydPqfAadUhWhPUIidSPpaYfFwrpDSHhZFI7k6jhPzOCPUowJ4uw4F54mpLPGhvR%2BonVFtQ%2FeU5NpRegZYa0Ph51dD4%2FDaPcpfNy13fabNtfT%2F099N6aw%2BtnX%2FdivUlh9aY9603Y9XOtuIOCr3p7gcz%2FetAZ%2BHPvvfvPXM1mlMOhuWA%2F22JKyfrwUcIh%2BP6VfZik8X3FdcbYoiuqX83l%2FcvFadRXUVr6mi87Ilt8i%2BbO5YCkolkJ%2BCSOyVsuyOJACQWSHM2ElAi8jViFwFTGNwGPbGoGLiBkEziLWILArMYDVg133pxHYQPat35dP%2B20Xbzu%2FTZ%2FLZrIg%2Fc5vEHL2lqAWIfb5FiEOd7sQ7s6Hwe73muL9hEnx8w4h6hLeI5QROiAUjLxM%2BZ1HVHVEUBEafFgUjQhZhE4IMqfoHkFGqgcEEaHzHPluYlmdN9Td3whJzarIP%2BHnX1HL0pFsCN80thHOkPeq8BOwqZTNSoL9CLTGcUfV0xjUTlriifBz0ElrHB2ualL72xBcXLC1huBynnVRzYpDaAQG4nCeqhrYpc%2Byl0HES9Jjov6vV4t6wajeeP10b7F8gBDUoJkM%2FM19Ly8ZeI%2F%2B%2FgNe%2Fdw9qQgAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/next-plugin/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_components%2FCard%2FPhotoCard%2FPhotoCardBasicCarousel.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81VTY%2BjMAy991dYI81qesgIWvox6WV%2Fxt5WKQmQaUhQCB06q%2F73VcI3pbSHPeyp1HGen58d%2B%2F33ngYqoB78WQAYTWTODVcSw5noN4QMF9JkPl0e7DErDaIsVJpUPlJJdlhcF%2B81iu9QMtVgaCaI4WdmL1OeZ4JcMMSaU2v44tQkGHzPe%2B1jrB3G4BAgUtKgnH8zDN7hMVF1ZjoS6gtDwill0tqOSlOmkSaUF%2Fno1mk5YNB8rByV0UWv7xrcTbjz2TifW0qdx9Z5tAJFgpX9493w%2BChUeDrc1XmkXEYo5TJGRmWdMSx0rjSGb6VSxAdc9qOMyDFXojAO2mF4h0kyo7AJ43Fibqv7MQM%2FEgDgs8gNjy4oVNIwaTCETBqm7VFMMgz7rKwqa4xKMQQusmCRwbCpaLg%2BiZROcfUpiGG%2F3tDGex1UnMywmhSwU2Ist%2BKWImJnJk1%2B%2B0LCfnfX%2FBut2nTuN2qy7Dww%2BFkJuRKcDpy2y36Ja0JT3IgQ1vyj4XZ03ACOJDzFWhWSolAJC9KHPzr4luaMR0OBsogUwljjtScFe9RptR56oE4j1iqo%2FtflwRBkZeMjuGSo7cD3wGnWJjUkWw2MiSx8fzlRjcCrw05p2eUWV28%2BIyE3FwxexWGiJq34US1%2Be8d%2FSkQ%2BnA3NbLW%2FyLA0sw2PtPqyFAujbs9CJYpU5hj8qHtYtbYp0TGXVeeP5fYayz1h104%2BIngsuwd8JDmz1Xk4w7sMP%2BeH4%2BkfD8ceMiaRYdrhtwm8vBzuNu3sCATgMmemnhrzTyxaPjNK0qlFOdX6ExEuTuKfKaOcQB5qxiQQSeEt5RLVkB9bLyuXLkgT0sOJ3WMw3NX2kZQoTwi1660fZkWGw%2BLu8rVN3YWhNWxv6%2Ft%2B1W19N958JLV%2Fv1%2FrUTDcFNeZXPj4AQ5IRjckxQTJ9W0YOeEWNG6PSrDb7m0JKjMp28psPsaVmZLMu2UzRXr1HOn1POmO3W67%2B4%2FZtdotrn8BL0yQfPwKAAA%3D%22%7D"
export var active = '_8d4o4df';
export var card = '_8d4o4d1';
export var cardComment = '_8d4o4dm _8d4o4dl';
export var cardCommentLarge = '_8d4o4dn';
export var cardContainer = '_8d4o4d0';
export var cardPhotoImage = '_8d4o4d8';
export var cardPhotoLink = '_8d4o4d7';
export var cardPhotoWrapper = '_8d4o4dk';
export var cardUser = '_8d4o4di';
export var container = '_8d4o4d6';
export var dot = '_8d4o4dc';
export var dots = '_8d4o4d9';
export var dummyThumbnail = '_8d4o4da';
export var embra = '_8d4o4d4';
export var imagesContainer = '_8d4o4d3';
export var large = '_8d4o4dh';
export var reformLabel = '_8d4o4dg';
export var selected = '_8d4o4db';
export var selectedSnapLabel = '_8d4o4de _8d4o4dd';
export var thumbnailView = '_8d4o4d2';
export var userNameWrapper = '_8d4o4dj';
export var viewport = '_8d4o4d5';